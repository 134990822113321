import React, { useContext } from 'react';
import { Button, Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import Section from '../../Section';
import TextLibrary from '../../TextLibrary';
import { ThemeContext } from 'styled-components';
import { ButtonType, getButtonOnClick } from '../../ContentfulButton';
import { LocaleContext } from '../../ContextProvider';
import { useLocation } from '@reach/router';

const PromoText = ({
  id = '',
  title = '[Promo Text Title]',
  titleTextAlign = 'center',
  summary = '[Summary - Lorem ipsum dolor]',
  backgroundColor = 'dark',
  textColor = 'white',
  callToAction,
  summaryTextAlign = 'center',
  cta = {
    id: '',
    label: 'button',
    variant: 'primary',
    onClickType: ButtonType.INTERNAL_LINK,
    onClick: '',
  },
  showCta = false,
}: {
  id?: string;
  title?: string;
  titleTextAlign?: string;
  summary?: string;
  backgroundColor?: string;
  textColor?: string;
  callToAction?: string;
  summaryTextAlign?: string;
  cta?: {
    id: string;
    label: string;
    variant: string;
    onClickType: ButtonType;
    onClick: string;
  };
  showCta?: boolean;
}) => {
  const localeData = useContext(LocaleContext);
  const { search } = useLocation();

  const buttonOnClick = getButtonOnClick({
    type: cta.onClickType,
    onClick: cta.onClick,
    rootPath: localeData.rootPath,
    searchParams: search,
  });

  return (
    <>
      <Section.Container
        id={id}
        backgroundColor={backgroundColor}
        color={textColor}
      >
        <TextLibrary.H1 textAlign={[titleTextAlign]} mb={[3]}>
          {title}
        </TextLibrary.H1>
        <TextLibrary.H5
          as="h2"
          mx={['10%', '20%', '20%']}
          textAlign={[summaryTextAlign]}
          mb={[0]}
        >
          {summary}
        </TextLibrary.H5>

        {showCta ? (
          <Box textAlign={[titleTextAlign]} mt={[3]}>
            <Button
              variant={cta?.variant}
              type="button"
              onClick={buttonOnClick}
            >
              {cta?.label}
            </Button>
          </Box>
        ) : (
          ''
        )}
      </Section.Container>
    </>
  );
};

export default PromoText;
