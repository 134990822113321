import {
  faChevronLeft,
  faChevronRight,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { FunctionComponent } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { Article, BlogCategory, HeroImage } from '../objects/Blog';
import { InternalLinkWithQuery } from '../utils/LinkWithQuery';
import ContextProvider from './ContextProvider';
import PromoText from './Home/sections/PromoText';
import Layout from './Layout';
import { H5, Paragraph, Small } from './TextLibrary';

const PaginationControls = ({
  currentPage,
  totalPages,
  pagesToShowRight = 2,
  pagesToShowLeft = 1,
}: {
  currentPage: number;
  totalPages: number;
  pagesToShowRight?: number;
  pagesToShowLeft?: number;
}) => {
  if (totalPages <= 1) {
    return <></>;
  }

  const isFirst = currentPage === 1;
  const isLast = currentPage === totalPages;
  const prevPagePath =
    currentPage - 1 === 1 ? '../' : `../` + (currentPage - 1).toString();
  const nextPagePath = isFirst
    ? (currentPage + 1).toString()
    : `../` + (currentPage + 1).toString();
  const rootPath = currentPage === 1 ? '' : '../';

  const NumberBox: FunctionComponent<{ isCurrent: boolean; to?: string }> = ({
    isCurrent,
    children,
    to = '',
  }) => {
    const isCurrentStyles = {
      borderStyle: 'solid',
      borderWidth: '2px',
      borderRadius: '4px',
      px: 1,
      borderColor: 'cloudFitTealDark',
      color: 'cloudFitTealDark',
      fontWeight: 'bold',
    };

    const linkStyles = {
      ...isCurrentStyles,
      borderColor: 'light',
      color: 'greyHigh',
      fontWeight: 'normal',
      ':hover': {
        ...isCurrentStyles,
      },
    };

    const boxStyle = {
      a: { ':hover': { textDecoration: 'none' } },
      mx: 1,
    };

    if (to) {
      return (
        <Box sx={boxStyle}>
          <InternalLinkWithQuery to={to}>
            <Box sx={isCurrent ? isCurrentStyles : linkStyles}>{children}</Box>
          </InternalLinkWithQuery>
        </Box>
      );
    } else {
      return (
        <Box sx={boxStyle}>
          <Box sx={isCurrent ? isCurrentStyles : linkStyles}>{children}</Box>
        </Box>
      );
    }
  };

  // Generate links to left of current page
  // Not the first record but still present in the first set of pages
  const linksToTheLeft: React.ReactElement[] = [];
  if (!isFirst) {
    Array.from({ length: pagesToShowLeft }).forEach((_, i) => {
      const pageToLink = currentPage + i - pagesToShowLeft;
      const pageLink = rootPath + pageToLink.toString();
      if (pageToLink > 1) {
        if (i === 0 && pageToLink > 2) {
          linksToTheLeft.push(<Box key="previousPagesGap">...</Box>);
        }
        linksToTheLeft.push(
          <NumberBox key={pageLink} isCurrent={false} to={pageLink}>
            {pageToLink}
          </NumberBox>,
        );
      }
    });
  }

  const linksToTheRight: React.ReactElement[] = [];
  if (currentPage < totalPages - 1) {
    Array.from({ length: pagesToShowRight }).forEach((_, i) => {
      const pageToLink = currentPage + i + 1;
      const pageLink = rootPath + pageToLink.toString();
      if (pageToLink < totalPages) {
        linksToTheRight.push(
          <NumberBox key={pageLink} isCurrent={false} to={pageLink}>
            {pageToLink}
          </NumberBox>,
        );
        if (i === pagesToShowRight - 1 && pageToLink < totalPages - 1) {
          linksToTheRight.push(<Box key="nextPagesGap">...</Box>);
        }
      }
    });
  }

  // generate links to right of current page

  return (
    <H5
      as="div"
      pb={5}
      sx={{
        fontWeight: 'normal',
        color: 'greyHigh',
        a: {
          ':hover': {
            color: 'cloudFitTealDark',
            textDecoration: 'none',
            fontWeight: 'bold',
          },
        },
      }}>
      <Flex width="100%" justifyContent="center" alignItems="center">
        {!isFirst && (
          <InternalLinkWithQuery to={prevPagePath} rel="prev">
            <Box px={2}>
              <FontAwesomeIcon icon={faChevronLeft} title="Previous Page" />
            </Box>
          </InternalLinkWithQuery>
        )}
        {!isFirst && (
          <NumberBox isCurrent={false} to="../">
            1
          </NumberBox>
        )}

        {linksToTheLeft}
        <NumberBox isCurrent={true}>{currentPage}</NumberBox>
        {linksToTheRight}
        {!isLast && (
          <NumberBox isCurrent={false} to={rootPath + totalPages.toString()}>
            {totalPages.toString()}
          </NumberBox>
        )}

        {!isLast && (
          <InternalLinkWithQuery to={nextPagePath} rel="next">
            <Box px={2}>
              <FontAwesomeIcon icon={faChevronRight} title="Next Page" />
            </Box>
          </InternalLinkWithQuery>
        )}
      </Flex>
    </H5>
  );
};

export const TagsComponent = ({
  tags,
  slug,
}: {
  tags: [string];
  slug: string;
}): React.ReactElement => {
  const tagsComponent = tags.map((tag) => {
    return (
      <Small
        key={tag + slug}
        mb={[0]}
        mr={[2]}
        px={[2]}
        sx={{
          backgroundColor: 'cloudFitGrey',
          borderRadius: '6px',
        }}>
        {tag.toUpperCase()}
      </Small>
    );
  });

  return <>{tagsComponent}</>;
};

export const ArticleCard = ({
  articleData,
  blogRootPath = '.',
  isOdd,
}: {
  articleData: Article;
  blogRootPath?: string;
  isOdd: boolean;
}): React.ReactElement => {
  const HeroImageBox = ({ heroImage }: { heroImage: HeroImage }) => {
    const isGif = heroImage.mimeType === 'image/gif';
    return (
      <Box
        width={[1]}
        pb={[1, 2, 0]}
        height={['auto']}
        sx={{ position: 'static' }}>
        {!isGif && (
          <GatsbyImage
            alt={heroImage.description}
            image={getImage(heroImage.image)!}
            style={{ width: '100%' }}
          />
        )}
        {isGif && (
          <img
            alt={heroImage.description}
            src={heroImage.url}
            style={{ width: '100%' }}
          />
        )}
      </Box>
    );
  };

  const categoryLink = blogRootPath + '/' + articleData.category.slug;
  const articleLink = blogRootPath + '/' + articleData.slug;

  return (
    <Flex
      flexDirection="column"
      key={articleData.id}
      width={[1, '47.5%', '47.5%']}
      mb={[5]}
      mr={[0, isOdd ? '2.5%' : 0, isOdd ? '2.5%' : 0]}
      ml={[0, isOdd ? 0 : '2.5%', isOdd ? 0 : '2.5%']}
      sx={{ position: 'relative' }}>
      <header style={{ position: 'relative', zIndex: 1 }}>
        <Box
          sx={{
            position: 'absolute',
            borderTopColor: 'cloudFitOrangeA11y',
            borderTopWidth: '4px',
            borderTopStyle: 'solid',
            width: '100%',
            textAlign: 'left',
          }}>
          <Box
            sx={{
              backgroundColor: 'cloudFitOrangeA11y',
              color: 'light',
              display: 'inline-block',
              textTransform: 'uppercase',
              px: 2,
              py: 1,
            }}>
            <InternalLinkWithQuery to={categoryLink}>
              {articleData.category.name}
            </InternalLinkWithQuery>
          </Box>
        </Box>
      </header>
      <InternalLinkWithQuery to={articleLink}>
        <HeroImageBox heroImage={articleData.heroImage} />
      </InternalLinkWithQuery>

      <H5 as="h3" fontWeight={['bold']} textAlign={['left']} mt={3}>
        <InternalLinkWithQuery to={articleLink}>
          {articleData.title}
        </InternalLinkWithQuery>
      </H5>
      {/* <Flex width="100%" flexDirection="row" mb={[3]}>
        {articleData.tags && (
          <TagsComponent tags={articleData.tags} slug={articleData.slug} />
        )}
      </Flex> */}

      <Paragraph color="greyHigh">{articleData.description}</Paragraph>

      <Flex flexDirection="row" justifyContent="space-between" mb={3}>
        <Small mb={[0]}>{articleData.publishDate}</Small>
        <Small mb={[0]}>
          <FontAwesomeIcon icon={faClock} /> {articleData.readingTimeMinutes}{' '}
          min read
        </Small>
      </Flex>
    </Flex>
  );
};

export const ArticleListPage = ({
  blogRootPath,
  articlesData,
  category,
  numPages,
  currentPage,
  canonicalPath,
}: {
  blogRootPath: string;
  articlesData: [{ node: Article }];
  category: BlogCategory;
  numPages: number;
  currentPage: number;
  canonicalPath: string | null;
}): React.ReactElement => {
  // Generate article cards
  let cnt = 0;
  const articleCards = articlesData.map((articleData) => {
    cnt++;
    const isOdd = cnt % 2;

    return ArticleCard({
      articleData: articleData.node,
      blogRootPath: blogRootPath,
      isOdd: isOdd ? true : false,
    });
  });

  return (
    <ContextProvider localeCode={category.localeCode}>
      <Layout
        title={category.metaTitle}
        description={category.description.childMarkdownRemark.rawMarkdownBody}
        colorTheme="light"
        canonicalPath={canonicalPath}>
        <Box backgroundColor="light">
          <Box px={[3, 3, 5]} pt={[6]} maxWidth="960px" margin="auto">
            <PromoText
              key={category.id}
              id={category.id}
              title={category.name}
              titleTextAlign="center"
              summary={category.description.childMarkdownRemark.rawMarkdownBody}
              backgroundColor="light"
              textColor="dark"
              callToAction=""
              summaryTextAlign="center"
            />
            <Flex flexDirection={['row']} flexWrap="wrap" width="100%">
              {articleCards}
            </Flex>
            <PaginationControls
              currentPage={currentPage}
              totalPages={numPages}
            />
          </Box>
        </Box>
      </Layout>
    </ContextProvider>
  );
};

export default ArticleListPage;
